import { createIcon } from "@chakra-ui/icons";
import React from "react";

export const Slack = createIcon({
  viewBox: "0 0 16 16",
  path: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.95132 8.85024C2.95132 9.66394 2.2942 10.3216 1.48115 10.3216C0.668107 10.3216 0.0109863 9.66394 0.0109863 8.85024C0.0109863 8.03655 0.668107 7.37891 1.48115 7.37891H2.95132V8.85024ZM3.6864 8.85024C3.6864 8.03655 4.34352 7.37891 5.15657 7.37891C5.96962 7.37891 6.62674 8.03655 6.62674 8.85024V12.5286C6.62674 13.3423 5.96962 13.9999 5.15657 13.9999C4.34352 13.9999 3.6864 13.3423 3.6864 12.5286V8.85024Z"
        fill="#495057"
      />
      <path
        d="M5.15672 2.94268C4.34368 2.94268 3.68656 2.28503 3.68656 1.47134C3.68656 0.657643 4.34368 0 5.15672 0C5.96977 0 6.62689 0.657643 6.62689 1.47134V2.94268H5.15672ZM5.15672 3.68949C5.96977 3.68949 6.62689 4.34714 6.62689 5.16083C6.62689 5.97452 5.96977 6.63217 5.15672 6.63217H1.47017C0.65712 6.63217 0 5.97452 0 5.16083C0 4.34714 0.65712 3.68949 1.47017 3.68949H5.15672Z"
        fill="#495057"
      />
      <path
        d="M11.0487 5.16083C11.0487 4.34714 11.7058 3.68949 12.5189 3.68949C13.3319 3.68949 13.989 4.34714 13.989 5.16083C13.989 5.97452 13.3319 6.63217 12.5189 6.63217H11.0487V5.16083ZM10.3136 5.16083C10.3136 5.97452 9.65651 6.63217 8.84346 6.63217C8.03041 6.63217 7.37329 5.97452 7.37329 5.16083V1.47134C7.37329 0.657643 8.03041 0 8.84346 0C9.65651 0 10.3136 0.657643 10.3136 1.47134V5.16083Z"
        fill="#495057"
      />
      <path
        d="M8.84346 11.0573C9.65651 11.0573 10.3136 11.7149 10.3136 12.5286C10.3136 13.3423 9.65651 13.9999 8.84346 13.9999C8.03041 13.9999 7.37329 13.3423 7.37329 12.5286V11.0573H8.84346ZM8.84346 10.3216C8.03041 10.3216 7.37329 9.66394 7.37329 8.85024C7.37329 8.03655 8.03041 7.37891 8.84346 7.37891H12.53C13.3431 7.37891 14.0002 8.03655 14.0002 8.85024C14.0002 9.66394 13.3431 10.3216 12.53 10.3216H8.84346Z"
        fill="#495057"
      />
    </svg>
  ),
});

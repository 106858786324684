import { ComponentDefaultProps } from "@chakra-ui/react";
import { formatDistanceToNow } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { capitalize } from "lodash";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import { SingleContact } from "@/core/components/Contact/SingleContact";
import { SingleGroup } from "@/core/components/Group/SingleGroup";
import { useCrmEntitiesExport } from "@/core/hooks/useCrmEntitiesExport";
import { SingleCompany } from "@/modules/Company/SingleCompany";
import { SyncTooltip } from "@/modules/Settings/Crm/SyncSettings/SyncTooltip";
import { Drilldown } from "core/components/Drilldown";
import { useCrmEntities } from "core/hooks/useCrmEntities";
import usePagination from "core/hooks/usePagination";
import { ICrmSync } from "core/models/crmSyncs";
import { Integration } from "core/models/dataMappings";
import { AppObjectType, IAppObject } from "core/types/AppObject";

interface IRecordsDrilldown extends ComponentDefaultProps {
  appObject: IAppObject;
  integration: Integration;
  isOpen: boolean;
  onClose: () => void;
  crmSync?: ICrmSync;
}

export const RecordsDrilldown: React.FC<IRecordsDrilldown> = ({
  appObject,
  integration,
  isOpen,
  onClose,
  crmSync,
}) => {
  const { currentPage, nextPage } = usePagination({
    withURL: false,
    persist: false,
  });
  const { crmEntities, pagy, isLoadingCrmEntities } = useCrmEntities({
    integration,
    objectType: appObject.objectType,
    page: currentPage,
    crmSync,
  });

  const { requestCsvExport, csvExportLoading } = useCrmEntitiesExport({
    appObject,
    integration,
  });

  return (
    <Drilldown isOpen={isOpen} onClose={onClose}>
      {(Drilldown) => (
        <>
          <Drilldown.Header>
            <Drilldown.Title
              title={
                pagy?.count === 1
                  ? `${pagy?.count} ${appObject.singularName.toLowerCase()}`
                  : `${pagy?.count.toLocaleString("en-US")} ${appObject.pluralName.toLowerCase()}`
              }
              description={`Total ${appObject.pluralName.toLowerCase()} synced`}
              isLoading={isLoadingCrmEntities}
            />
            <Drilldown.DownloadButton
              isDisabled={Number(pagy?.count) <= 0}
              isLoading={csvExportLoading}
              onClick={requestCsvExport}
            />
          </Drilldown.Header>
          <Drilldown.Body pt={4}>
            <Drilldown.List
              items={crmEntities}
              hasMore={crmEntities.length < Number(pagy?.count)}
              itemRenderer={({ item }) =>
                appObject.objectType === AppObjectType.User ? (
                  <>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <SingleContact
                          description={`${capitalize(item.action)} ${formatDistanceToNow(
                            zonedTimeToUtc(new Date(item.updatedAt), "UTC"),
                            {
                              addSuffix: true,
                            },
                          )}`}
                          key={item.entityId}
                          id={item.entityId}
                        />
                      </TooltipTrigger>
                      <TooltipContent side="left">
                        <SyncTooltip item={item} />
                      </TooltipContent>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    {appObject.objectType === AppObjectType.Group ? (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div>
                            <SingleGroup
                              key={item.entityId}
                              id={item.entityId}
                            />
                          </div>
                        </TooltipTrigger>
                        <TooltipContent side="left">
                          <SyncTooltip item={item} />
                        </TooltipContent>
                      </Tooltip>
                    ) : (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div>
                            <SingleCompany
                              key={item.entityId}
                              id={item.entityId}
                            />
                          </div>
                        </TooltipTrigger>
                        <TooltipContent side="left">
                          <SyncTooltip item={item} />
                        </TooltipContent>
                      </Tooltip>
                    )}
                  </>
                )
              }
              loadNext={() => nextPage()}
              isLoading={isLoadingCrmEntities}
              emptyStateText={`No records synced`}
            />
          </Drilldown.Body>
        </>
      )}
    </Drilldown>
  );
};

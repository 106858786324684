import { PuzzlePieceIcon } from "@heroicons/react/24/solid";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useCurrentApp } from "core/hooks/useCurrentApp";
import { Item } from "modules/Navigation/Sidebar/Item";
import { ItemText } from "modules/Navigation/Sidebar/ItemText";

export const Integrations = () => {
  const { id: appId } = useCurrentApp();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(`/a/${appId}/integrations/computed-traits`);
    // eslint-disable-next-line no-undef
    window.analytics.track(
      "invite_team_members_clicked",
      {
        app_id: appId,
      },
      { context: { groupId: appId } },
    );
  }, [navigate, appId]);

  return (
    <Item onClick={handleClick} className="py-0">
      <PuzzlePieceIcon className="black relative -left-[2px] h-[15px] w-[15px]" />
      <ItemText className="relative -left-[1.5px]">Integrations</ItemText>
    </Item>
  );
};

import { motion } from "framer-motion";

import { useNavigation } from "core/hooks/useNavigation";
import { cx } from "helpers/cx";
import { NewBaseSidebar } from "modules/Navigation/Sidebar/NewBaseSidebar";
import { Sidebar } from "modules/Navigation/Sidebar/Sidebar";

export type CurrentSection =
  | "Discover"
  | "Home"
  | "New report"
  | "Reports"
  | "Events"
  | "Templates"
  | "Feed"
  | "Settings"
  | "People"
  | "Getting started"
  | "AIExplorer"
  | "Integrations";

export interface ISidebarProps {
  currentSection: CurrentSection;
}

export const NoAnalyticsSidebar: React.FC<ISidebarProps> = ({
  currentSection,
}) => {
  const { navigation, x } = useNavigation();
  const { isOpen } = navigation;

  return (
    <div>
      <motion.nav
        initial={{ x }}
        animate={{ x }}
        exit={{ x }}
        transition={{ type: "spring", bounce: 0, duration: 0.4 }}
        className={cx(
          isOpen ? "scrollbar-hide flex h-full w-full overflow-auto" : "",
        )}
      >
        {!isOpen && <NewBaseSidebar />}
        {isOpen && <Sidebar currentSection={currentSection} />}
      </motion.nav>
    </div>
  );
};

import { formatDistanceToNow } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

interface ISyncItem {
  action: string;
  updatedAt: string;
  traits: Record<string, string>;
}

interface ISyncTooltip {
  item: ISyncItem;
}

export const SyncTooltip: React.FC<ISyncTooltip> = ({ item }) => {
  return (
    <div className="flex flex-col gap-1">
      <p className="text-sm font-medium">
        {" "}
        <p className="inline-block capitalize">{item.action}</p>{" "}
        {formatDistanceToNow(zonedTimeToUtc(new Date(item.updatedAt), "UTC"), {
          addSuffix: true,
        })}{" "}
        with traits:
      </p>
      {Object.keys(item.traits).length > 0 ? (
        Object.entries(item.traits || {}).map(([key, value]) => (
          <div key={key}>
            <span className="font-medium">{key}:</span> {String(value)}
          </div>
        ))
      ) : (
        <p className="text-sm text-gray-500">No traits synced</p>
      )}
    </div>
  );
};

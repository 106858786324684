import { Box, Flex, Text } from "@chakra-ui/react";
import {
  ArrowLeftOnRectangleIcon as ArrowLeftOnRectangleIconSolid,
  BanknotesIcon as BanknotesIconSolid,
  CircleStackIcon as CircleStackIconSolid,
  CodeBracketIcon as CodeBracketIconSolid,
  Cog6ToothIcon as Cog6ToothIconSolid,
  CubeIcon as CubeIconSolid,
  InboxArrowDownIcon as InboxArrowDownIconSolid,
  TagIcon as TagIconSolid,
  UsersIcon as UsersIconSolid,
  ChevronDownIcon,
  ChevronLeftIcon,
} from "@heroicons/react/20/solid";
import {
  ArrowLeftOnRectangleIcon,
  BanknotesIcon,
  CircleStackIcon,
  CodeBracketIcon,
  Cog6ToothIcon,
  CubeIcon,
  InboxArrowDownIcon,
  TagIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import { cn } from "@/lib/utils";
import { NEW_SIDEBAR, USAGE_BASED_PRICING } from "core/constants/features";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import useFlag from "core/hooks/useFlag";
import { BaseSidebar } from "modules/Navigation/Sidebar/BaseSidebar";
import { MenuLink, MenuLinkProps } from "routes/Settings/MenuLink";

const MENU: MenuLinkProps[] = [
  {
    label: "General",
    path: "settings/general",
    Icon: { Outline: Cog6ToothIcon, Solid: Cog6ToothIconSolid },
  },
  {
    label: "Members",
    path: "settings/team",
    Icon: { Outline: UsersIcon, Solid: UsersIconSolid },
  },
  {
    label: "Labels",
    path: "settings/labels",
    Icon: { Outline: TagIcon, Solid: TagIconSolid },
  },
  {
    label: "Billing",
    path: "settings/billing",
    Icon: { Outline: BanknotesIcon, Solid: BanknotesIconSolid },
  },
  {
    label: "Digest",
    path: "settings/digest",
    Icon: { Outline: InboxArrowDownIcon, Solid: InboxArrowDownIconSolid },
  },
  {
    label: "Objects",
    path: "settings/objects",
    Icon: { Outline: CubeIcon, Solid: CubeIconSolid },
  },

  {
    label: "Developer tools",
    path: "settings/developers",
    Icon: { Outline: CodeBracketIcon, Solid: CodeBracketIconSolid },
  },
  {
    label: "Data sources",
    path: "settings/integrations",
    Icon: { Outline: CircleStackIcon, Solid: CircleStackIconSolid },
    subMenu: [
      {
        label: "June SDK",
        path: "settings/integrations/june-sdk",
      },

      {
        label: "Segment",
        path: "settings/integrations/segment",
      },
      {
        label: "Freshpaint",
        path: "settings/integrations/freshpaint",
      },
      {
        label: "Rudderstack",
        path: "settings/integrations/rudderstack",
      },
    ],
  },
  {
    label: "Importers",
    path: "settings/integrations",
    Icon: {
      Outline: ArrowLeftOnRectangleIcon,
      Solid: ArrowLeftOnRectangleIconSolid,
    },
    subMenu: [
      {
        label: "Amplitude",
        path: "settings/integrations/amplitude",
      },
      {
        label: "Mixpanel",
        path: "settings/integrations/mixpanel",
      },
    ],
  },
];

type ToggleProps = { isOpen: boolean; onToggle: () => void };
const Toggle: React.FC<ToggleProps> = ({ isOpen, onToggle }) => (
  <Flex onClick={onToggle}>
    <motion.div animate={{ rotate: isOpen ? 0 : -90 }}>
      <ChevronDownIcon className="h-4 w-4  text-gray-500 group-hover:block group-hover:text-gray-500" />
    </motion.div>
  </Flex>
);

const NavAccordion: React.FC<MenuLinkProps> = ({ subMenu, label, Icon }) => {
  const [isOpen, setIsOpen] = useState(true);
  const hasNewSidebar = useFlag(NEW_SIDEBAR);

  return (
    <Flex direction="column">
      <MenuLink
        action={<Toggle isOpen={isOpen} onToggle={() => setIsOpen(isOpen)} />}
        label={label}
        Icon={Icon}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <div
          className={cn(
            "ml-[28px] flex flex-col",
            hasNewSidebar ? "gap-0" : "mt-2 gap-2",
          )}
        >
          {subMenu?.map((subItem) => (
            <MenuLink key={subItem.label} {...subItem} />
          ))}
        </div>
      )}
    </Flex>
  );
};

export const SettingsNavBar: React.FC = () => {
  const app = useCurrentApp();
  const hasUsageBasedPricing = useFlag(USAGE_BASED_PRICING);
  const hasNewSidebar = useFlag(NEW_SIDEBAR);

  if (hasNewSidebar)
    return (
      <div className="sticky top-0 flex h-screen min-h-screen min-w-[290px] flex-col justify-between">
        <div className="flex h-screen flex-1 flex-col overflow-y-auto">
          <div className="flex w-full">
            <div className="flex flex-1 flex-col gap-1 py-4">
              <Box mb={2}>
                <NavLink to={`/a/${app.id}/home`}>
                  <div className="mb-2 ml-4">
                    <div className="flex items-center gap-2">
                      <ChevronLeftIcon className="h-5 w-5" />
                      <p className="text-md font-semibold">Back to app</p>
                    </div>
                  </div>
                </NavLink>
              </Box>
              {MENU.map((item, index) => {
                const animationDelay = 0.1 + index * 0.05;

                if (
                  hasNewSidebar &&
                  ["Labels", "Digest"].includes(item.label)
                ) {
                  return null;
                }

                if (item.subMenu) {
                  return (
                    <motion.div
                      key={item.label}
                      initial={{ x: -10, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: animationDelay, duration: 0.2 }}
                    >
                      <NavAccordion {...item} />
                    </motion.div>
                  );
                }

                if (item.label === "Billing" && !hasUsageBasedPricing)
                  return null;

                return (
                  <motion.div
                    key={item.label}
                    initial={{ x: -10, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: animationDelay, duration: 0.2 }}
                  >
                    <MenuLink {...item} />
                  </motion.div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="sticky top-0 flex h-screen min-h-screen min-w-[290px] flex-col justify-between">
      <div className="flex h-screen flex-1 flex-col overflow-y-auto">
        <div className="flex w-full">
          <BaseSidebar />

          <div className="flex flex-1 flex-col gap-3 py-4">
            <Box mb={2}>
              <NavLink to={`/a/${app.id}/home`}>
                <Flex gap={2} px={4}>
                  <Text color="gray.900" fontWeight="semibold">
                    Settings
                  </Text>
                </Flex>
              </NavLink>
            </Box>
            {MENU.map((item, index) => {
              const animationDelay = 0.1 + index * 0.05;

              if (item.subMenu) {
                return (
                  <motion.div
                    key={item.label}
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: animationDelay, duration: 0.3 }}
                  >
                    <NavAccordion {...item} />
                  </motion.div>
                );
              }

              if (item.label === "Billing" && !hasUsageBasedPricing)
                return null;

              return (
                <motion.div
                  key={item.label}
                  initial={{ x: -20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: animationDelay, duration: 0.3 }}
                >
                  <MenuLink {...item} />
                </motion.div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

import { Button, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import {
  ICohortGroups,
  IntervalType,
} from "core/components/CohortTable/CohortTable.types";
import { Drilldown } from "core/components/Drilldown";
import { GroupType } from "core/components/Group/GroupListSingleGroup";
import { SingleGroup } from "core/components/Group/SingleGroup";
import { Toggle, ToggleButton } from "core/components/Toggle";
import { useAppObjects } from "core/hooks/useAppObjects";
import { useView } from "core/hooks/useView";
import { RetentionDrilldownHeader } from "core/modules/reports/report-types/Retention/RetentionDrilldownHeader";
import { SingleCompany } from "modules/Company/SingleCompany";

export const CohortGroups: React.FC<ICohortGroups> = ({
  isOpen,
  onClose,
  cohortData,
  intervalType,
  cohortStartDate,
  cohortIntervalStartDate,
  viewInsight,
}) => {
  const cohort = cohortData.find(({ startDate }) =>
    startDate.includes(cohortStartDate),
  )!;
  const cohortIntervalData = cohort?.data.find(({ startDate }) =>
    startDate.includes(cohortIntervalStartDate),
  )!;
  const [retained, setRetained] = useState(true);
  const [groups, setGroups] = useState<string[]>(
    cohortIntervalData?.retainedGroupIds || [],
  );
  const [groupsCount, setGroupsCount] = useState(
    cohortIntervalData?.retainedGroupsCount || 0,
  );
  const [retainedGroupsView, hasMoreRetainedGroups, loadMoreRetainedGroups] =
    useView(groups, 10);
  const { activeAppObject } = useAppObjects();

  const appObject = viewInsight?.appObject || activeAppObject;
  const isGroupLevel = appObject?.slug === "group";
  const isCompanyLevel = appObject?.slug === "company";

  const intervalNumber =
    cohortIntervalData?.[intervalType as IntervalType] || 0;
  const isSubcohort = intervalNumber && intervalNumber > 0;
  const dates = {
    cohortStartDate,
    cohortEndDate: cohort?.endDate,
    cohortIntervalStartDate,
    cohortIntervalEndDate: cohortIntervalData?.endDate,
  };

  const toggleGroups = (retained: boolean) => {
    setRetained(retained);
  };

  const onDrilldownClose = () => {
    onClose();
    // Reset retained value
    setRetained(true);
  };

  useEffect(() => {
    const cohort = cohortData.find(({ startDate }) =>
      startDate.includes(cohortStartDate),
    )!;
    const cohortIntervalData = cohort?.data.find(({ startDate }) =>
      startDate.includes(cohortIntervalStartDate),
    )!;
    const selectedGroups = retained
      ? cohortIntervalData?.retainedGroupIds
      : cohortIntervalData?.droppedGroupIds;
    const selectedGroupsCount = retained
      ? cohortIntervalData?.retainedGroupsCount
      : cohortIntervalData?.droppedGroupsCount;
    setGroups(selectedGroups || []);
    setGroupsCount(selectedGroupsCount || 0);
  }, [cohortData, cohortIntervalStartDate, cohortStartDate, retained]);

  return (
    cohortData && (
      <Drilldown isOpen={isOpen} onClose={onDrilldownClose}>
        {(Drilldown) => (
          <>
            <Drilldown.Header>
              <Flex
                direction="column"
                alignItems="center"
                justifyContent="center"
                pt={isSubcohort ? 2 : 0}
              >
                <RetentionDrilldownHeader
                  {...dates}
                  isLoading={false}
                  intervalType={intervalType}
                  intervalNumber={intervalNumber}
                  count={groupsCount}
                  retained={retained}
                />
                {isSubcohort ? (
                  <Toggle h={"2rem"} mt={6}>
                    <ToggleButton
                      isDisabled={false}
                      isSelected={retained}
                      onToggle={() => toggleGroups(true)}
                    >
                      Retained
                    </ToggleButton>
                    <ToggleButton
                      isDisabled={false}
                      isSelected={!retained}
                      onToggle={() => toggleGroups(false)}
                    >
                      Dropped
                    </ToggleButton>
                  </Toggle>
                ) : null}
              </Flex>
            </Drilldown.Header>
            <Drilldown.Body>
              {isGroupLevel &&
                retainedGroupsView.map((id: string, index: number) => (
                  <SingleGroup key={id} groupType={GroupType.Group} id={id} />
                ))}
              {isCompanyLevel &&
                retainedGroupsView.map((id: string, index: number) => (
                  <SingleCompany key={index} id={id} />
                ))}
              {hasMoreRetainedGroups && (
                <Flex my={2} justify="center">
                  <Button onClick={loadMoreRetainedGroups}>Load more</Button>
                </Flex>
              )}
            </Drilldown.Body>
          </>
        )}
      </Drilldown>
    )
  );
};

import { UsersIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/Components/ui/accordion";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useGetAudiencesQuery } from "core/models/audiences";
import { IAudience } from "core/types/Audience";
import { Item } from "modules/Navigation/Sidebar/Item";
import { ItemText } from "modules/Navigation/Sidebar/ItemText";
import { Items } from "modules/Navigation/Sidebar/Sidebar";

export const People = () => {
  const currentApp = useCurrentApp();
  const { data, isLoading } = useGetAudiencesQuery({
    appId: Number(currentApp.id),
    pinned: true,
  });
  const pathArray = window.location.pathname.split(`/`);
  const audienceId = pathArray.includes("audience")
    ? pathArray[pathArray.indexOf("audience") + 1]
    : "";

  return (
    <AccordionItem value={Items.People} className="hover:underline-none">
      <AccordionTrigger className="px-[18px] py-0">
        <div className="flex items-center gap-2">
          <UsersIcon className="relative left-[2px] h-[14px] w-[14px]" />
          <p className="relative left-[2px] text-[13px] font-medium">People</p>
        </div>
      </AccordionTrigger>
      <AccordionContent className="relative flex h-full w-full border-none px-2 pb-2 pt-2">
        <div className="absolute bottom-[15px] left-[25px] top-2 w-[1.5px] bg-gray-200 " />
        <div className="h-full w-full pl-6">
          {isLoading ? (
            <div className="flex flex-col gap-1">
              <div className="mx-3 flex h-[22px] animate-pulse flex-row items-center justify-center space-x-5">
                <div className="h-full w-full rounded-md bg-gray-200"></div>
              </div>
              <div className="mx-3 flex h-[22px] animate-pulse flex-row items-center justify-center space-x-5">
                <div className="h-full w-full rounded-md bg-gray-300"></div>
              </div>
              <div className="mx-3 flex h-[22px] animate-pulse flex-row items-center justify-center space-x-5">
                <div className="h-full w-full rounded-md bg-gray-200"></div>
              </div>
            </div>
          ) : (
            <motion.div
              animate={{ opacity: 1, height: "auto" }}
              initial={{ opacity: 0, height: 0 }}
              exit={{ opacity: 0, height: 0 }}
              className="flex flex-col gap-0.5"
            >
              <Item
                isCurrent={window.location.href.includes(`/people`)}
                to={`/a/${currentApp.id}/people`}
                className="group relative ml-2 flex justify-between px-2"
              >
                <div className="-ml-1 flex items-center">
                  <div className="ml-0.5 line-clamp-1 max-w-[210px]">
                    <ItemText className="max-w-[210px] text-ellipsis">
                      All
                    </ItemText>
                  </div>
                </div>
              </Item>
              {data?.audiences?.map((audience: IAudience) => {
                return (
                  <Item
                    key={audience.id}
                    isCurrent={String(audienceId) === String(audience.id)}
                    to={`/a/${currentApp.id}/audience/${audience.id}`}
                    className="group relative ml-2 flex justify-between px-2"
                  >
                    <div className="-ml-1 flex items-center">
                      <div className="ml-0.5 line-clamp-1 max-w-[280px]">
                        <ItemText className="max-w-[280px] text-ellipsis">
                          {audience.name}
                        </ItemText>
                      </div>
                    </div>
                  </Item>
                );
              })}
              <Item
                to={`/a/${currentApp.id}/audiences`}
                className="group relative ml-2 flex justify-between px-2"
              >
                <div className="-ml-1 flex items-center">
                  <div className="ml-0.5 line-clamp-1 max-w-[210px]">
                    <p className="text-[13px] text-gray-600">View all</p>
                  </div>
                </div>
              </Item>
            </motion.div>
          )}
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

import {
  ChevronDownIcon,
  ChevronLeftIcon,
  CodeBracketIcon as CodeBracketIconSolid,
  InboxArrowDownIcon as InboxArrowDownIconSolid,
  BoltIcon as BoltIconSolid,
} from "@heroicons/react/20/solid";
import {
  CodeBracketIcon,
  InboxArrowDownIcon,
} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import { cn } from "@/lib/utils";
import { NEW_SIDEBAR, USAGE_BASED_PRICING } from "core/constants/features";
import { Attio } from "core/design-system/components/Icon/Logos/Attio";
import { Hubspot } from "core/design-system/components/Icon/Logos/Hubspot";
import { Salesforce } from "core/design-system/components/Icon/Logos/Salesforce";
import { Slack } from "core/design-system/components/Icon/Logos/Slack";
import { Stripe } from "core/design-system/components/Icon/Logos/Stripe";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import useFlag from "core/hooks/useFlag";
import { MenuLink, MenuLinkProps } from "routes/Settings/MenuLink";

const MENU: MenuLinkProps[] = [
  {
    label: "Computed traits",
    path: "integrations/computed-traits",
    Icon: { Outline: BoltIconSolid, Solid: BoltIconSolid },
  },
  {
    label: "HubSpot",
    path: "integrations/hubspot",
    Icon: { Outline: Hubspot, Solid: Hubspot },
  },
  {
    label: "Attio",
    path: "integrations/attio",
    Icon: { Outline: Attio, Solid: Attio },
  },
  {
    label: "Salesforce",
    path: "integrations/salesforce",
    Icon: { Outline: Salesforce, Solid: Salesforce },
  },
  {
    label: "Email",
    path: "integrations/email",
    Icon: { Outline: InboxArrowDownIcon, Solid: InboxArrowDownIconSolid },
  },
  {
    label: "Stripe",
    path: "integrations/stripe",
    Icon: { Outline: Stripe, Solid: Stripe },
  },
  {
    label: "Slack",
    path: "integrations/slack",
    Icon: { Outline: Slack, Solid: Slack },
  },
  {
    label: "API",
    path: "integrations/api",
    Icon: { Outline: CodeBracketIcon, Solid: CodeBracketIconSolid },
  },
];

type ToggleProps = { isOpen: boolean; onToggle: () => void };
const Toggle: React.FC<ToggleProps> = ({ isOpen, onToggle }) => (
  <div className="flex" onClick={onToggle}>
    <motion.div animate={{ rotate: isOpen ? 0 : -90 }}>
      <ChevronDownIcon className="h-4 w-4  text-gray-500 group-hover:block group-hover:text-gray-500" />
    </motion.div>
  </div>
);

const NavAccordion: React.FC<MenuLinkProps> = ({ subMenu, label, Icon }) => {
  const [isOpen, setIsOpen] = useState(true);
  const hasNewSidebar = useFlag(NEW_SIDEBAR);

  return (
    <div className="flex flex-col">
      <MenuLink
        action={<Toggle isOpen={isOpen} onToggle={() => setIsOpen(isOpen)} />}
        label={label}
        Icon={Icon}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <div
          className={cn(
            "ml-[28px] flex flex-col",
            hasNewSidebar ? "gap-0" : "mt-2 gap-2",
          )}
        >
          {subMenu?.map((subItem) => (
            <MenuLink key={subItem.label} {...subItem} />
          ))}
        </div>
      )}
    </div>
  );
};

export const IntegrationsNavBar: React.FC = () => {
  const app = useCurrentApp();
  const hasUsageBasedPricing = useFlag(USAGE_BASED_PRICING);

  return (
    <div className="sticky top-0 flex h-screen min-h-screen min-w-[290px] flex-col justify-between">
      <div className="flex h-screen flex-1 flex-col overflow-y-auto">
        <div className="flex w-full">
          <div className="flex flex-1 flex-col gap-1 py-4">
            <div className="mb-2">
              <NavLink to={`/a/${app.id}/home`}>
                <div className="mb-2 ml-4">
                  <div className="flex items-center gap-2">
                    <ChevronLeftIcon className="h-5 w-5" />
                    <p className="text-md font-semibold">Back to app</p>
                  </div>
                </div>
              </NavLink>
            </div>
            {MENU.map((item, index) => {
              const animationDelay = 0.1 + index * 0.05;

              if (item.subMenu) {
                return (
                  <motion.div
                    key={item.label}
                    initial={{ x: -10, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: animationDelay, duration: 0.2 }}
                  >
                    <NavAccordion {...item} />
                  </motion.div>
                );
              }

              if (item.label === "Billing" && !hasUsageBasedPricing)
                return null;

              return (
                <motion.div
                  key={item.label}
                  initial={{ x: -10, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: animationDelay, duration: 0.2 }}
                >
                  <MenuLink {...item} />
                </motion.div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

import { Divider } from "@chakra-ui/react";

import { NEW_SIDEBAR } from "@/core/constants/features";
import useFlag from "@/core/hooks/useFlag";
import { cn } from "@/lib/utils";
import DefaultHeader from "modules/Pages/DefaultHeader";
import { DangerZone } from "modules/Settings/DangerZone";
import { DataManagement } from "modules/Settings/DataManagement";
import MobileWidgets from "modules/Settings/MobileWidgets";
import { UserSettings } from "modules/Settings/UserSettings";
import { WorkspaceSettings } from "modules/Settings/WorkspaceSettings";

const SECTION = {
  name: "General",
  title: "General",
  description: "Manage your workspace settings",
};

export const GeneralSettings: React.FC = () => {
  const hasNewSidebar = useFlag(NEW_SIDEBAR);
  return (
    <div
      className={cn(
        "flex  flex-col gap-5",
        hasNewSidebar ? "w-[985px]" : "w-full",
      )}
    >
      <DefaultHeader w="inherit" section={SECTION} />
      <WorkspaceSettings />
      <Divider />
      <UserSettings />
      <Divider />
      <MobileWidgets />
      <Divider />
      <DataManagement />
      <Divider />
      <DangerZone />
    </div>
  );
};

import { createIcon } from "@chakra-ui/icons";
import React from "react";

export const Attio = createIcon({
  viewBox: "0 0 16 16",
  path: (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8343 9.1566L14.4977 7.01748C14.4977 7.01748 14.4927 7.00854 14.4897 7.00454L14.3843 6.83648C14.1854 6.51729 13.8413 6.32634 13.4654 6.32533L11.3124 6.31836L11.1622 6.55906L8.58952 10.6762L8.44727 10.9039L9.52529 12.6263C9.72422 12.9466 10.0683 13.1375 10.4472 13.1375H13.4644C13.8364 13.1375 14.1884 12.9416 14.3853 12.6273L14.4917 12.4573C14.4917 12.4573 14.4957 12.4523 14.4967 12.4503L15.8352 10.3082C16.055 9.95816 16.055 9.50566 15.8352 9.1566H15.8343ZM15.4265 10.0526L14.0879 12.1947C14.082 12.2047 14.075 12.2126 14.069 12.2206C14.0223 12.2733 13.9617 12.2803 13.9348 12.2803C13.904 12.2803 13.8284 12.2713 13.7797 12.1937L12.4411 10.0517C12.4262 10.0278 12.4132 10.0029 12.4013 9.97605C12.3894 9.95018 12.3805 9.92437 12.3725 9.8975C12.3427 9.7901 12.3427 9.67473 12.3725 9.56734C12.3874 9.51465 12.4103 9.46191 12.4401 9.41418L13.7767 7.27409C13.7767 7.27409 13.7787 7.27111 13.7797 7.26909C13.8115 7.22135 13.8513 7.19948 13.8861 7.19251C13.9 7.18857 13.912 7.18756 13.9219 7.18559C13.9269 7.18559 13.9318 7.18559 13.9368 7.18559C13.9676 7.18559 14.0442 7.19549 14.0919 7.27308L15.4285 9.41221C15.5508 9.60709 15.5508 9.85774 15.4285 10.0526H15.4265Z"
        fill="#495057"
      />
      <path
        d="M11.879 3.98091C12.0978 3.62984 12.0978 3.17834 11.879 2.82929L10.5424 0.690171L10.431 0.510169C10.2312 0.190944 9.88711 0 9.50916 0H6.49194C6.11506 0 5.77095 0.190944 5.57006 0.511164L0.167072 9.15812C0.0586743 9.33118 0 9.53106 0 9.73393C0 9.9368 0.0576797 10.1367 0.166078 10.3087L1.61503 12.6289C1.81492 12.9491 2.15901 13.139 2.53591 13.139H5.55313C5.93204 13.139 6.27614 12.9481 6.47502 12.6278L6.58544 12.4528C6.58544 12.4528 6.58544 12.4528 6.58544 12.4508L6.58741 12.4468L7.66443 10.7244L10.8567 5.61581L11.877 3.98187L11.879 3.98091ZM11.5638 3.4051C11.5638 3.51547 11.533 3.62686 11.4703 3.72531L6.17769 12.1962C6.12996 12.2728 6.05338 12.2818 6.02256 12.2818C5.9917 12.2818 5.91613 12.2728 5.86743 12.1962L4.52984 10.0532C4.40851 9.85926 4.40851 9.60961 4.52984 9.41372L9.82245 0.944753C9.87018 0.867183 9.94676 0.858237 9.97758 0.858237C10.0084 0.858237 10.085 0.867183 10.1337 0.945748L11.4703 3.08489C11.533 3.18334 11.5638 3.29467 11.5638 3.4051Z"
        fill="#495057"
      />
    </svg>
  ),
});

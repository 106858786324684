import { Spinner } from "@chakra-ui/react";
import { StarIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import { XIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/Components/ui/accordion";
import { Button } from "@/Components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useFavourites } from "core/hooks/useFavourites";
import {
  FavouriteObjectType,
  FavouriteRoute,
  IFavourite,
} from "core/models/favourites";
import { Item } from "modules/Navigation/Sidebar/Favourites/Item";
import { Item as ItemContainer } from "modules/Navigation/Sidebar/Item";
import { ItemText } from "modules/Navigation/Sidebar/ItemText";
import { Items } from "modules/Navigation/Sidebar/Sidebar";

export const FavouritesSection: React.FC<{
  objectTypes?: FavouriteObjectType[];
}> = ({ objectTypes = [] }) => {
  const currentApp = useCurrentApp();
  const { favourites, onDeleteFavourite, isDeleting, isLoading } =
    useFavourites(currentApp.id, objectTypes);
  const navigate = useNavigate();

  return (
    <AccordionItem value={Items.Favourites} className="hover:underline-none">
      <AccordionTrigger className="px-[18px] py-0">
        <div className="flex items-center gap-2">
          <StarIcon className="relative left-[2px] h-[15px] w-[15px]" />
          <p className="relative left-[2px] text-[13px] font-medium">
            Favourites
          </p>
        </div>
      </AccordionTrigger>
      <AccordionContent className="relative flex h-full w-full border-none px-2 pb-2 pt-1">
        <div className="absolute bottom-[10px] left-[26px] top-2 w-[1.5px] bg-gray-200 " />
        <div className="h-full w-full pl-6">
          {isLoading ? (
            <div className="flex flex-col gap-1">
              <div className="mx-3 flex h-[22px] animate-pulse flex-row items-center justify-center space-x-5">
                <div className="h-full w-full rounded-md bg-gray-200"></div>
              </div>
              <div className="mx-3 flex h-[22px] animate-pulse flex-row items-center justify-center space-x-5">
                <div className="h-full w-full rounded-md bg-gray-300"></div>
              </div>
              <div className="mx-3 flex h-[22px] animate-pulse flex-row items-center justify-center space-x-5">
                <div className="h-full w-full rounded-md bg-gray-200"></div>
              </div>
            </div>
          ) : (
            <motion.div
              animate={{ opacity: 1, height: "auto" }}
              initial={{ opacity: 0, height: 0 }}
              exit={{ opacity: 0, height: 0 }}
              className="flex flex-col gap-1"
            >
              {favourites?.map((favourite: IFavourite) => {
                return (
                  <ItemContainer
                    key={favourite.id}
                    isCurrent={window.location.href.includes(
                      `/a/${currentApp.id}/${FavouriteRoute[favourite.objectType]}/${favourite.objectId}`,
                    )}
                    onClick={() =>
                      navigate(
                        `/a/${currentApp.id}/${FavouriteRoute[favourite.objectType]}/${favourite.objectId}`,
                      )
                    }
                    className="group relative ml-1 flex justify-between pr-0"
                  >
                    <div className="-ml-1 flex w-full items-center">
                      <div className="ml-0.5 line-clamp-1 w-full max-w-[300px]">
                        <ItemText className="flex w-full max-w-[300px] items-center justify-between text-ellipsis">
                          <div className="max-w-[220px]">
                            <Item favourite={favourite} />
                          </div>
                          <div className="hidden items-center group-hover:flex">
                            <Tooltip>
                              <TooltipTrigger asChild>
                                {isDeleting ? (
                                  <Spinner size="xs" />
                                ) : (
                                  <Button
                                    variant="unstyled"
                                    size="unset"
                                    className="p-0.5 text-gray-900 [&_svg]:size-3"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onDeleteFavourite(favourite);
                                    }}
                                  >
                                    <XIcon />
                                  </Button>
                                )}
                              </TooltipTrigger>
                              <TooltipContent>
                                Remove from my favourites
                              </TooltipContent>
                            </Tooltip>
                          </div>
                        </ItemText>
                      </div>
                    </div>
                  </ItemContainer>
                );
              })}
            </motion.div>
          )}
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import { Spinner } from "@/Components/ui/spinner";
import {
  ADD_ANALYTICS_TO_SIDEBAR,
  NEW_SIDEBAR,
} from "@/core/constants/features";
import useFlag from "core/hooks/useFlag";
import { useViews, ViewLocation } from "core/hooks/useViews";
import { selectAppId, selector as appSelector } from "core/models/app";

const Home = () => {
  const { isLoading } = useSelector(appSelector);
  const appId = useSelector(selectAppId);
  const { views } = useViews({ location: ViewLocation.Home, pinned: true });
  const navigate = useNavigate();
  const hasNewSidebar = useFlag(NEW_SIDEBAR);
  const hasAnalyticsToSidebar = useFlag(ADD_ANALYTICS_TO_SIDEBAR);

  useEffect(() => {
    if (hasNewSidebar && !hasAnalyticsToSidebar) {
      navigate(`/a/${appId}/people`);
      return;
    }

    if (views && views?.length > 0) {
      navigate(`/a/${appId}/home/${views?.[0]?.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [views?.length]);

  if (!appId) {
    return <Navigate to={`/`} />;
  }

  if (isLoading) return <Spinner size="medium" />;

  return null;
};

export default Home;

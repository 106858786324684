import { Cog8ToothIcon } from "@heroicons/react/24/solid";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { DEMO_APP_ID } from "@/core/constants/appIds";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { Item } from "modules/Navigation/Sidebar/Item";
import { ItemText } from "modules/Navigation/Sidebar/ItemText";

export const Settings = () => {
  const { id: appId } = useCurrentApp();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(`/a/${appId}/settings/team`);
    // eslint-disable-next-line no-undef
    window.analytics.track(
      "invite_team_members_clicked",
      {
        app_id: appId,
      },
      { context: { groupId: appId } },
    );
  }, [navigate, appId]);

  if (appId === DEMO_APP_ID) return null;

  return (
    <Item onClick={handleClick}>
      <Cog8ToothIcon className="black relative -left-[3px] h-4 w-4" />
      <ItemText className="relative -left-[3px]">Settings</ItemText>
    </Item>
  );
};

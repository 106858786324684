import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { cx } from "class-variance-authority";
import { useParams } from "react-router-dom";

import { Label } from "@/Components/ui/label";
import { Switch } from "@/Components/ui/switch";
import { IViewInsight } from "@/core/models/viewInsights";
import { PaywallPopover } from "core/components/Paywall/Popover";
import { ReportSettings } from "core/components/ReportSettings";
import {
  AUDIENCE_TYPE,
  CONVERSION_WINDOW_TYPE,
  ENUM_TYPE,
  MULTI_EVENT_TYPE,
  SETTINGS_TYPE,
  SINGLE_EVENT_TYPE,
} from "core/constants/report-setup";
import { useAppObjects } from "core/hooks/useAppObjects";
import {
  IUpdateInsightPayload,
  MeasureToIcon,
} from "core/hooks/useEditViewInsight";
import { AudienceCountPreview } from "core/modules/reports/setup/Audience/CountPreview";
import AudiencePicker from "core/modules/reports/setup/Editor/Audience";
import { ConversionWindowPicker } from "core/modules/reports/setup/Editor/ConversionWindowPicker";
import { DynamicMultiEventPicker } from "core/modules/reports/setup/Editor/DynamicEvent";
import EnumPicker from "core/modules/reports/setup/Editor/Enum";
import { MultiEventPicker } from "core/modules/reports/setup/Editor/Event";
import { Plan } from "core/types/App";
import { IAppObject } from "core/types/AppObject";
import { IReport } from "core/types/Report";
import {
  ITemplateConfig,
  ITemplateConfigSetupSection,
} from "core/types/TemplateConfig";
import { HumanizedMeasureLabels, Measure } from "core/types/ViewInsight";
import { AudienceMenu } from "modules/ViewInsight/Audience/AudienceMenu";

export const SetupContainer: React.FC<{
  viewInsight: IViewInsight;
  report: IReport;
  templateConfig: ITemplateConfig;
  measure: Measure;
  appObject: IAppObject;
  isAddingNewInsight?: boolean;
  useViewAudience: boolean;
  onUpdateReport: () => void;
  onUpdateInsight: (payload: IUpdateInsightPayload) => void;
}> = ({
  viewInsight,
  report,
  templateConfig,
  measure,
  appObject,
  isAddingNewInsight,
  useViewAudience,
  onUpdateReport,
  onUpdateInsight,
}) => {
  const { groupId, objectId } = useParams();
  const { appObjects } = useAppObjects();
  const { groupAppObject, companyAppObject } = useAppObjects();
  const isCompanyProfile = window.location.pathname.includes("/company/");
  const object = isCompanyProfile ? companyAppObject : groupAppObject;

  return (
    <div className="flex h-full flex-col overflow-y-auto pb-20 [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden">
      <div className="border-b border-gray-200 p-5">
        <p className="mb-2 text-sm font-medium">Measure</p>
        <Menu matchWidth>
          <PaywallPopover
            feature="company profile insights"
            redirect="people"
            plan={Plan.Growth}
            overridePaywall={!Boolean(groupId) || isAddingNewInsight}
          >
            <MenuButton
              textAlign="left"
              w="full"
              as={Button}
              rightIcon={<ChevronDownIcon />}
              bg="gray.50"
              border="none"
              textTransform="capitalize"
            >
              <div className="flex items-center gap-1">
                {measure && MeasureToIcon[measure]}
                {measure && measure.split("_").join(" ")}
              </div>
            </MenuButton>
          </PaywallPopover>
          <MenuList>
            {Object.values(Measure).map((measure) => (
              <MenuItem
                textTransform="capitalize"
                onClick={() => {
                  onUpdateInsight({ measure });
                }}
              >
                <div className="flex items-center gap-1">
                  {MeasureToIcon[measure]}
                  {HumanizedMeasureLabels[measure]}
                  {measure === Measure.Audience && (
                    <span className="rounded-[4px] bg-purple-50 px-1.5 py-0.5 text-[10px] font-medium text-purple-500">
                      New
                    </span>
                  )}
                </div>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </div>
      {viewInsight.measure === Measure.Audience && (
        <div className="border-b border-gray-200 p-5">
          <p className="mb-2 text-sm font-medium">Audience</p>
          <AudienceMenu
            viewInsight={viewInsight}
            onUpdateInsight={onUpdateInsight}
          />
        </div>
      )}
      {!groupId && ![Measure.Features].includes(measure) && (
        <div className="border-b border-gray-200 px-5 py-5 pb-5">
          <p className="mb-2 text-sm font-medium">Object</p>
          <Menu matchWidth>
            <PaywallPopover
              feature="company level metrics"
              redirect="people"
              plan={Plan.Growth}
            >
              <MenuButton
                textAlign="left"
                w="full"
                as={Button}
                rightIcon={<ChevronDownIcon />}
                bg="gray.50"
                border="none"
                textTransform="capitalize"
              >
                <div className="flex items-center gap-1">
                  {appObject.pluralName}
                </div>
              </MenuButton>
            </PaywallPopover>
            <MenuList>
              {appObjects
                .filter((a) => a.isEnabled)
                .map((appObject) => (
                  <MenuItem
                    textTransform="capitalize"
                    onClick={() =>
                      onUpdateInsight({ objectType: appObject.objectType })
                    }
                  >
                    <div className="flex items-center gap-1">
                      {appObject.pluralName}
                    </div>
                  </MenuItem>
                ))}
            </MenuList>
          </Menu>
        </div>
      )}
      {viewInsight.measure !== Measure.Audience && (
        <div className="flex flex-col gap-y-5 py-5">
          {templateConfig?.setup?.setupSections.map(
            (section: ITemplateConfigSetupSection, index: number) => {
              if (section.type === MULTI_EVENT_TYPE) {
                if (section.isDynamic) {
                  return (
                    <div
                      key={index}
                      className="border-b border-gray-200 px-5 pb-5"
                    >
                      <p className="mb-4 text-sm font-medium capitalize">
                        {`${section.configKey}s`}
                      </p>
                      <DynamicMultiEventPicker
                        setup={templateConfig.setup}
                        section={section}
                        onUpdateReport={onUpdateReport}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={index}
                      className="border-b border-gray-200 px-5 pb-5"
                    >
                      <p className="mb-4 text-sm font-medium">
                        {section.title}
                      </p>
                      <MultiEventPicker
                        setup={templateConfig.setup}
                        section={section}
                        onUpdateReport={onUpdateReport}
                      />
                    </div>
                  );
                }
              }

              if (section.type === AUDIENCE_TYPE) {
                return (
                  <div
                    key={index}
                    className="border-b border-gray-200 px-5 pb-5"
                  >
                    <p className="mb-4 text-sm font-medium">Audience</p>
                    {!(groupId || objectId) && (
                      <div className="mb-4 flex items-center space-x-2">
                        <Switch
                          id="use_dashboard_audience"
                          size="sm"
                          checked={useViewAudience}
                          onCheckedChange={() =>
                            onUpdateInsight({
                              useViewAudience: !useViewAudience,
                            })
                          }
                        />
                        <Label htmlFor="use_dashboard_audience">
                          Use dashboard's audience filters
                        </Label>
                      </div>
                    )}

                    <div
                      className={cx(
                        "rounded-md p-3 transition-all duration-500 ease-in-out",
                        useViewAudience
                          ? "pointer-events-none scale-[0.98] opacity-30"
                          : "scale-100 border-transparent opacity-100",
                      )}
                    >
                      {!(groupId || objectId) && (
                        <div className="mb-4">
                          <AudienceCountPreview
                            audienceFilters={{
                              filterGroups: report.audience?.filterGroups,
                              joinOperator: report.audience?.joinOperator,
                            }}
                            showAvatars={false}
                          />
                        </div>
                      )}
                      {(groupId || objectId) && (
                        <p className="mb-4 flex items-center gap-1 rounded-md bg-gray-100 px-2 py-1 text-xs text-gray-700">
                          <InformationCircleIcon className="h-4 w-4" /> These
                          filters are only applied to the users of the{" "}
                          {object.singularName.toLowerCase()} you are viewing
                        </p>
                      )}
                      <AudiencePicker
                        disabledTooltip={
                          useViewAudience
                            ? "This insight is using the dashboard's audience filters"
                            : ""
                        }
                        key={String(useViewAudience)}
                        section={section}
                        isDisabled={useViewAudience}
                        onUpdateReport={onUpdateReport}
                      />
                    </div>
                  </div>
                );
              }

              if (section.type === SINGLE_EVENT_TYPE) {
                return (
                  <div
                    key={index}
                    className="border-b border-gray-200 px-5 pb-5"
                  >
                    <p className="mb-4 text-sm font-medium">{section.title}</p>
                    <MultiEventPicker
                      setup={templateConfig.setup}
                      section={section}
                      singleEvent
                      onUpdateReport={onUpdateReport}
                    />
                  </div>
                );
              }

              if (section.type === CONVERSION_WINDOW_TYPE) {
                return (
                  <div
                    key={index}
                    className="border-b border-gray-200 px-5 pb-5"
                  >
                    <div className="mb-4 flex items-center gap-1">
                      <p className="text-sm font-medium">Conversion Window</p>
                      <Tooltip label={section.description} placement="top">
                        <InformationCircleIcon className="h-4 w-4" />
                      </Tooltip>
                    </div>
                    <ConversionWindowPicker />
                  </div>
                );
              }

              if (section.type === ENUM_TYPE) {
                return (
                  <div
                    key={index}
                    className="border-b border-gray-200 px-5 pb-5"
                  >
                    <p className="mb-4 text-sm font-medium">{section.title}</p>
                    <EnumPicker section={section} />
                  </div>
                );
              }

              if (section.type === SETTINGS_TYPE) {
                return (
                  <div key={index} className="px-5">
                    <p className="mb-4 text-sm font-medium">{section.title}</p>
                    <ReportSettings report={report} config={templateConfig} />
                  </div>
                );
              }

              return null;
            },
          )}
        </div>
      )}
    </div>
  );
};

import { useSelector } from "react-redux";

import { NEW_SIDEBAR } from "@/core/constants/features";
import useFlag from "@/core/hooks/useFlag";
import { BookACallItem } from "core/components/Onboarding/BookACallItem";
import { DEMO_APP_ID } from "core/constants/appIds";
import { useMauThreshold } from "core/hooks/useMauThreshold";
import { selector as appSelector } from "core/models/app";
import { DemoToggle } from "modules/Navigation/Sidebar/Footer/DemoToggle";
import { FeedbackBox } from "modules/Navigation/Sidebar/Footer/FeedbackBox";
import { InviteLink } from "modules/Navigation/Sidebar/Footer/InviteLink";
import { ImportProgress } from "modules/Navigation/Sidebar/ImportProgress";
import { Settings } from "modules/Navigation/Sidebar/Settings";
import { UsageThreshold } from "modules/Navigation/Sidebar/UsageThreshold";

export const Footer: React.FC = () => {
  const { data: app, isLoading: isLoadingApp } = useSelector(appSelector);
  const { userCount } = useMauThreshold();
  const loadedApp = !isLoadingApp && app && app.id;
  const hasNewSidebar = useFlag(NEW_SIDEBAR);

  const showDemoToggle = app?.id === DEMO_APP_ID || userCount < 100;

  return (
    <>
      <div className="flex flex-col gap-1 pt-2">
        {loadedApp && (
          <>
            <ImportProgress />
            {hasNewSidebar && <Settings />}
            {showDemoToggle && <DemoToggle />}
            <InviteLink />
            <FeedbackBox />
            <UsageThreshold />
            <BookACallItem />
          </>
        )}
      </div>
    </>
  );
};

import { Navigate, Outlet } from "react-router-dom";

import { PageContainer } from "core/components/PageContainer";
import { DEMO_APP_ID } from "core/constants/appIds";
import { NEW_SIDEBAR } from "core/constants/features";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import useFlag from "core/hooks/useFlag";
import { IntegrationsNavBar } from "routes/Settings/IntegrationsNavBar";

export const Integrations: React.FC = () => {
  const { id: appId } = useCurrentApp();
  const hasNewSidebar = useFlag(NEW_SIDEBAR);

  if (Number(appId) === DEMO_APP_ID) {
    return <Navigate to={`/a/${appId}/home`} />;
  }

  if (hasNewSidebar) {
    return (
      <div className="flex min-h-screen bg-gray-50">
        <IntegrationsNavBar />
        <div className="border-1 m-2 w-full rounded-lg bg-white">
          <div className="mx-auto max-w-7xl px-12 py-8">
            <Outlet />
          </div>
        </div>
      </div>
    );
  }

  return (
    <PageContainer>
      <Outlet />
    </PageContainer>
  );
};

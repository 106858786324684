import { useLocation } from "react-router-dom";

import { NEW_SIDEBAR } from "@/core/constants/features";
import useFlag from "@/core/hooks/useFlag";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { cx } from "helpers/cx";
import { Item } from "modules/Navigation/Sidebar/Item";
import { ItemText } from "modules/Navigation/Sidebar/ItemText";

export type MenuLinkProps = {
  label: string;
  path?: string;
  isNew?: boolean;
  isBeta?: boolean;
  Icon?: {
    Outline: React.FC<{ className?: string }>;
    Solid: React.FC<{ className?: string }>;
  };
  subMenu?: MenuLinkProps[];
  onClick?: () => void;
  action?: React.ReactNode;
};

export const MenuLink: React.FC<MenuLinkProps> = ({
  label,
  path,
  Icon,
  onClick,
  action,
  isNew,
  isBeta,
}) => {
  const app = useCurrentApp();
  const location = useLocation();

  const isActive = path ? location.pathname.includes(path) : false;
  const to = path ? `/a/${app.id}/${path}` : undefined;

  const hasNewSidebar = useFlag(NEW_SIDEBAR);

  return (
    <Item isCurrent={isActive} to={to} onClick={onClick} className="py-1">
      <div className="flex h-full w-full items-center justify-between">
        <div className="flex items-center gap-2">
          {Icon &&
            (hasNewSidebar ? (
              <Icon.Solid
                className={cx(
                  "h-4 w-4",
                  isActive ? "text-black" : "text-gray-800",
                )}
              />
            ) : (
              <Icon.Outline
                className={cx(
                  "h-5 w-5",
                  isActive ? "text-purple-500" : "text-gray-600",
                )}
              />
            ))}
          <ItemText>{label}</ItemText>
          {action}
        </div>
        {isNew && (
          <span className="text-xs font-semibold text-blue-500">New</span>
        )}
        {isBeta && (
          <span className="text-xs font-semibold text-orange-500">Beta</span>
        )}
      </div>
    </Item>
  );
};

import {
  closestCenter,
  DragEndEvent,
  DndContext,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { PlusIcon } from "@heroicons/react/20/solid";
import { PresentationChartBarIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/Components/ui/accordion";
import { Skeleton } from "@/Components/ui/skeleton";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useViews, ViewLocation } from "core/hooks/useViews";
import {
  IView,
  useAddViewMutation,
  useBatchUpdateOrderMutation,
  usePinViewMutation,
  useUpdateViewMutation,
} from "core/models/views";
import { Item } from "modules/Navigation/Sidebar/Item";
import { ItemText } from "modules/Navigation/Sidebar/ItemText";
import { Items } from "modules/Navigation/Sidebar/Sidebar";
import { SortableView } from "modules/Navigation/Sidebar/SortableView";

export const Dashboards = () => {
  const { views: viewsData, isLoadingViews } = useViews({
    location: ViewLocation.Home,
    pinned: true,
  });
  const [views, setViews] = useState<IView[]>(viewsData || []);
  const pathArray = window.location.pathname.split(`/`);
  const viewId = pathArray.includes("home")
    ? pathArray[pathArray.indexOf("home") + 1]
    : "";
  const { id: appId } = useCurrentApp();
  const [batchUpdateOrder] = useBatchUpdateOrderMutation();
  const [pinView] = usePinViewMutation();
  const [updateView] = useUpdateViewMutation();
  const [addView] = useAddViewMutation();
  const navigate = useNavigate();

  const onAddDashboard = async function () {
    const { data } = (await addView({
      appId,
      name: "Untitled",
      pin: false,
    })) as { data: IView };
    navigate(`/a/${appId}/home/${data?.id}?new=true`);
    toast.success(`Dashboard created`, {
      description: `You can now start adding insights to this dashboard.`,
    });
  };

  useEffect(() => {
    if (viewsData) {
      setViews(viewsData);
    }
  }, [viewsData]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setViews((views) => {
        const oldIndex = views.findIndex((view) => view.id === active.id);
        const newIndex = views.findIndex((view) => view.id === over?.id);

        const reorderedViews = arrayMove(views, oldIndex, newIndex).map(
          (view, index) => ({
            ...view,
            order: index,
          }),
        );

        batchUpdateOrder({
          appId: Number(appId),
          views: reorderedViews,
        });

        return reorderedViews;
      });
    }
  }

  function handleUnpin(view: IView) {
    pinView({
      appId: Number(appId),
      viewId: Number(view.id),
      pinned: !view.pinned,
    });
  }

  function handleEmojiClick(emoji: string, viewId: number) {
    updateView({
      id: Number(viewId),
      appId: Number(appId),
      emoji: emoji,
    });
  }

  return (
    <AccordionItem value={Items.Dashboard} className="hover:underline-none">
      <AccordionTrigger className="px-[18px] py-0">
        <div className="flex items-center gap-2">
          <PresentationChartBarIcon className="relative left-[2px] h-[14px] w-[14px]" />
          <p className="relative left-[2px] text-[13px] font-medium">
            Dashboards
          </p>
        </div>
      </AccordionTrigger>
      <AccordionContent className="relative flex h-full w-full border-none px-2 pb-2 pt-2">
        <div className="absolute bottom-[15px] left-[26px] top-2 w-[1.5px] bg-gray-200 " />
        <div className="h-full w-full pl-6">
          <motion.div
            animate={{ opacity: 1, height: "auto" }}
            initial={{ opacity: 0, height: 0 }}
            exit={{ opacity: 0, height: 0 }}
            className="flex flex-col gap-0.5"
          >
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={views}
                strategy={verticalListSortingStrategy}
              >
                {isLoadingViews ? (
                  <div className="flex flex-col gap-2 px-4">
                    <Skeleton className="h-[20px] rounded-md" />
                    <Skeleton className="h-[20px] rounded-md" />
                    <Skeleton className="h-[20px] rounded-md" />
                  </div>
                ) : (
                  views?.map((view) => (
                    <SortableView
                      viewId={viewId}
                      view={view}
                      onUnpin={handleUnpin}
                      onEmojiClick={handleEmojiClick}
                    />
                  ))
                )}
              </SortableContext>
            </DndContext>
            <Item
              className="group relative ml-2 flex justify-between px-2 py-1"
              onClick={onAddDashboard}
            >
              <div className="-ml-1 flex items-center">
                <div className="ml-0.5 line-clamp-1 flex max-w-[210px] items-center gap-[10px]">
                  <PlusIcon className="ml-0.5 h-4 w-4 text-black" />
                  <ItemText className="max-w-[210px] text-ellipsis">
                    Add dashboard
                  </ItemText>
                </div>
              </div>
            </Item>
            <Item
              to={`/a/${appId}/dashboards`}
              className="group relative ml-2 flex justify-between px-2"
            >
              <div className="-ml-1 flex items-center">
                <div className="ml-0.5 line-clamp-1 max-w-[210px]">
                  <p className="text-[13px] text-gray-600">View all</p>
                </div>
              </div>
            </Item>
          </motion.div>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

import { createIcon } from "@chakra-ui/icons";
import React from "react";

export const Salesforce = createIcon({
  viewBox: "0 0 16 12",
  path: (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66598 1.21391C7.17902 0.68 7.89206 0.347826 8.68163 0.347826C9.73206 0.347826 10.6468 0.933913 11.1355 1.80174C11.5599 1.61217 12.0295 1.50609 12.5216 1.50609C14.4155 1.50609 15.9495 3.05391 15.9495 4.96522C15.9495 6.87652 14.4138 8.42435 12.5216 8.42435C12.2903 8.42435 12.0642 8.40174 11.8468 8.35652C11.4173 9.12174 10.5982 9.64 9.65902 9.64C9.26598 9.64 8.8938 9.54957 8.56337 9.38783C8.12858 10.4122 7.11293 11.1304 5.93206 11.1304C4.75119 11.1304 3.65206 10.3513 3.24858 9.25913C3.07293 9.29565 2.89032 9.31652 2.7025 9.31652C1.23641 9.31652 0.048584 8.11652 0.048584 6.63478C0.048584 5.64174 0.582497 4.77565 1.37554 4.3113C1.21206 3.93565 1.12163 3.52 1.12163 3.08522C1.12163 1.38087 2.50424 0 4.21032 0C5.21206 0 6.1025 0.476522 6.66598 1.21391Z"
        fill="#495057"
      />
    </svg>
  ),
});

import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { MegaphoneIcon } from "@heroicons/react/24/outline";
import { MegaphoneIcon as MegaphoneIconSolid } from "@heroicons/react/24/solid";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";

import { NEW_SIDEBAR } from "@/core/constants/features";
import useFlag from "@/core/hooks/useFlag";
import { useToast } from "core/hooks/useToast";
import { useUserAuth } from "core/hooks/useUserAuth";
import { selector as appSelector } from "core/models/app";
import { useCreateFeedbackMutation } from "core/models/feedback";
import { ItemContainer } from "modules/Navigation/Sidebar/ItemContainer";
import { ItemText } from "modules/Navigation/Sidebar/ItemText";

export const FeedbackBox = () => {
  const { data: app } = useSelector(appSelector);
  const { currentUser } = useUserAuth();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const toast = useToast();

  const initialFocusRef = useRef(null);

  const [feedback, setFeedback] = useState("");
  const hasFeedback = feedback.length > 3;
  const [createFeedback, { isLoading: isLoadingFeedback }] =
    useCreateFeedbackMutation();
  const hasNewSidebar = useFlag(NEW_SIDEBAR);

  if (!app || !currentUser) return null;

  const onSubmitFeedback = () => {
    createFeedback({
      feedback,
      appId: Number(app.id),
      url: window.location.pathname,
      email: currentUser.email,
    }).then(() => {
      toast({
        title: "Feedback sent",
        description: "Thank you for your feedback!",
        duration: 5000,
      });
    });
    onClose();
    setFeedback("");
  };

  return (
    <>
      <ItemContainer onClick={onOpen}>
        {hasNewSidebar ? (
          <MegaphoneIconSolid className="h-4 w-4 text-black" />
        ) : (
          <MegaphoneIcon className="h-4 w-4 text-gray-600" />
        )}
        <ItemText>Feedback</ItemText>
      </ItemContainer>
      <Modal
        size="xl"
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialFocusRef}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Feedback</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              isDisabled={isLoadingFeedback}
              ref={initialFocusRef}
              m={0}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              focusBorderColor="white"
              border={"none"}
              size="sm"
              id="feedback"
              placeholder="Help us improve this page.."
            />
          </ModalBody>
          <ModalFooter>
            <Flex justify={"space-between"} align={"center"} w="full">
              <Text fontSize={"xs"}>
                Have a specific issue?{" "}
                <Text
                  onClick={() => window.Intercom("show")}
                  cursor={"pointer"}
                  className="openMessenger"
                  as="span"
                  color="primary"
                >
                  Chat with us
                </Text>
              </Text>
              <Flex align={"center"} gap={2} justify={"space-between"}>
                <Button onClick={onClose} size="sm">
                  Cancel
                </Button>
                <Button
                  onClick={onSubmitFeedback}
                  isDisabled={!hasFeedback}
                  isLoading={isLoadingFeedback}
                  size="sm"
                  colorScheme="purple"
                >
                  Send feedback
                </Button>
              </Flex>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

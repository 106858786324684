import { Flex, Switch } from "@chakra-ui/react";
import { WindowIcon as WindowIconOutline } from "@heroicons/react/24/outline";
import { WindowIcon as WindowIconSolid } from "@heroicons/react/24/solid";

import { NEW_SIDEBAR } from "@/core/constants/features";
import useFlag from "@/core/hooks/useFlag";
import { DEMO_APP_ID } from "core/constants/appIds";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useDemo } from "core/hooks/useDemo";
import { ItemContainer } from "modules/Navigation/Sidebar/ItemContainer";
import { ItemText } from "modules/Navigation/Sidebar/ItemText";

export const DemoToggle: React.FC = () => {
  const { id: appId } = useCurrentApp();
  const { toggleDemo } = useDemo();
  const hasNewSidebar = useFlag(NEW_SIDEBAR);

  return (
    <ItemContainer className="!cursor-default hover:bg-white">
      <Flex
        w="full"
        align="center"
        justify="space-between"
        data-testid="demo-toggle-container"
      >
        <Flex w="full" gap={2} align="center" justify="flex-start">
          {hasNewSidebar ? (
            <WindowIconSolid className="h-4 w-4 text-black" />
          ) : (
            <WindowIconOutline className="h-4 w-4 text-gray-600" />
          )}
          <ItemText>Demo mode</ItemText>
        </Flex>
        <Switch
          onChange={toggleDemo}
          size="sm"
          colorScheme="orange"
          isChecked={appId === DEMO_APP_ID}
          data-testid="demo-toggle-switch"
        />
      </Flex>
    </ItemContainer>
  );
};

import { CircularProgress } from "@chakra-ui/react";
import {
  Cog8ToothIcon,
  PuzzlePieceIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import { PresentationChartBarIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/Components/ui/tooltip";
import { ADD_ANALYTICS_TO_SIDEBAR } from "@/core/constants/features";
import { Event } from "@/core/design-system/components/Icon/Event";
import ArrowLeftBackSquare from "@/core/design-system/components/Icon/Interface/Essential/ArrowLeftBackSquare";
import useFlag from "@/core/hooks/useFlag";
import { CompanyJuneLogoIcon } from "core/design-system/components/Icon/Logos/CompanyJuneLogoIcon";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useGettingStarted } from "core/hooks/useGettingStarted";
import { useNavigation } from "core/hooks/useNavigation";
import { cx } from "helpers/cx";

interface MenuItem {
  icon: React.ReactNode;
  type: MenuItemType;
  paths: string[];
  defaultPath: string;
  isClickable: boolean;
  label?: string;
  open?: boolean;
  position?: "top" | "bottom";
}

export enum MenuItemType {
  GettingStarted = "Getting started",
  Home = "Home",
  Dashboards = "Dashboards",
  Events = "Events",
  People = "People",
  Integrations = "Integrations",
  Settings = "Settings",
}

export function getCurrentMenuItem(): MenuItem {
  const currentPath = window.location.pathname;
  return MENU_ITEMS.find((item) => {
    return item.paths.some((path) => currentPath.includes(path));
  }) as MenuItem;
}

const MENU_ITEMS: MenuItem[] = [
  {
    icon: <CompanyJuneLogoIcon h={5} w={5} />,
    type: MenuItemType.Home,
    paths: [],
    defaultPath: `/home`,
    isClickable: false,
    open: true,
    position: "top",
  },
  {
    icon: null,
    label: MenuItemType.GettingStarted,
    type: MenuItemType.GettingStarted,
    paths: ["/getting-started"],
    defaultPath: `/getting-started`,
    isClickable: true,
    open: false,
    position: "top",
  },
  {
    icon: <UsersIcon className="h-4 w-4" />,
    label: MenuItemType.People,
    type: MenuItemType.People,
    paths: [
      "/people",
      "/contact/",
      "/company/",
      "/group/",
      "/audiences",
      "/audience/",
      "/object/",
    ],
    defaultPath: `/people`,
    isClickable: true,
    open: true,
    position: "top",
  },
  {
    icon: <Event h={5} w={5} />,
    label: MenuItemType.Events,
    type: MenuItemType.Events,
    paths: ["/event/", "/events"],
    defaultPath: `/home`,
    isClickable: true,
    open: true,
    position: "top",
  },
  {
    icon: <PresentationChartBarIcon className="h-4 w-4" />,
    label: MenuItemType.Dashboards,
    type: MenuItemType.Dashboards,
    paths: [
      "/report/",
      "/reports",
      "/home",
      "/views",
      "/dashboards",
      "/dashboard/",
    ],
    defaultPath: `/home`,
    isClickable: true,
    open: true,
    position: "top",
  },
  {
    icon: <PuzzlePieceIcon className="h-4 w-4" />,
    label: MenuItemType.Integrations,
    type: MenuItemType.Integrations,
    paths: ["/integrations"],
    defaultPath: `/integrations/computed-traits`,
    isClickable: true,
    open: true,
    position: "top",
  },
  {
    icon: <Cog8ToothIcon className="h-4 w-4" />,
    label: MenuItemType.Settings,
    type: MenuItemType.Settings,
    paths: ["/settings"],
    defaultPath: `/settings`,
    isClickable: true,
    open: true,
    position: "bottom",
  },
];

export const NewBaseSidebar: React.FC = () => {
  const { id: appId } = useCurrentApp();
  const { navigation, toggleSidebar } = useNavigation();
  const navigate = useNavigate();
  const { progress, showGettingStarted } = useGettingStarted();
  const hasAnalyticsToSidebar = useFlag(ADD_ANALYTICS_TO_SIDEBAR);

  function isSelected(item: MenuItem) {
    return item.paths.some((path) => window.location.pathname.includes(path));
  }

  return (
    <div
      className={cx("flex h-screen flex-col justify-between")}
      style={{
        minWidth: navigation.isOpen ? "57px" : "57px",
        maxWidth: navigation.isOpen ? "57px" : "57px",
      }}
    >
      <div className="flex flex-col pt-1">
        {MENU_ITEMS.filter((item) => item.position === "top").map(
          (item: MenuItem, index) => {
            if (
              item.type === MenuItemType.GettingStarted &&
              !showGettingStarted
            ) {
              return null;
            }

            if (
              item.type === MenuItemType.Dashboards &&
              !hasAnalyticsToSidebar
            ) {
              return null;
            }

            const animationDelay = 0.1 + index * 0.05;

            return (
              <Tooltip key={item.type}>
                <TooltipTrigger asChild>
                  <motion.div
                    className="relative flex w-[57px] flex-col items-center justify-center gap-y-2"
                    initial={{ x: -10, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: animationDelay, duration: 0.2 }}
                  >
                    <div
                      className={cx(
                        "relative my-1 flex flex-col items-center justify-center px-2 py-2",
                        isSelected(item)
                          ? "rounded-lg border border-gray-200 bg-white text-black shadow-md"
                          : "bg-gray-50",
                        item.type === MenuItemType.Home
                          ? !navigation.isOpen
                            ? "cursor-pointer"
                            : ""
                          : "cursor-pointer",
                      )}
                      onClick={() => {
                        if (
                          item.type === MenuItemType.Home &&
                          navigation.isOpen
                        )
                          return;

                        if (
                          item.type === MenuItemType.Home &&
                          !navigation.isOpen
                        )
                          return toggleSidebar();

                        if (navigation.isOpen !== item.open) {
                          toggleSidebar();
                        }

                        navigate(`/a/${appId}${item.defaultPath}`);
                      }}
                    >
                      {item.type === MenuItemType.Home &&
                      !navigation.isOpen &&
                      !isSelected(
                        MENU_ITEMS.find(
                          (menuItem) =>
                            menuItem.type === MenuItemType.GettingStarted,
                        )!,
                      ) ? (
                        <ArrowLeftBackSquare
                          h={5}
                          w={5}
                          color="gray.900"
                          transform="rotate(180deg)"
                        />
                      ) : (
                        item.icon
                      )}
                      {item.type === MenuItemType.GettingStarted && (
                        <CircularProgress
                          thickness="14px"
                          value={progress}
                          color={isSelected(item) ? "primary" : "black"}
                          trackColor={
                            isSelected(item) ? "purple.100" : "gray.300"
                          }
                          size="16px"
                        />
                      )}
                    </div>
                  </motion.div>
                </TooltipTrigger>
                {item.label && (
                  <TooltipContent side="right">{item.label}</TooltipContent>
                )}
              </Tooltip>
            );
          },
        )}
      </div>
      <div className="flex flex-col pb-3">
        {MENU_ITEMS.filter((item) => item.position === "bottom").map(
          (item: MenuItem, index) => {
            if (
              item.type === MenuItemType.GettingStarted &&
              !showGettingStarted
            ) {
              return null;
            }

            const animationDelay = 0.1 + index * 0.05;

            return (
              <Tooltip key={item.type}>
                <TooltipTrigger asChild>
                  <motion.div
                    className="relative flex w-[57px] flex-col items-center justify-center gap-y-2"
                    initial={{ x: -10, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: animationDelay, duration: 0.2 }}
                  >
                    <div
                      className={cx(
                        "relative my-1 flex flex-col items-center justify-center px-2 py-2",
                        isSelected(item)
                          ? "rounded-lg border border-gray-200 bg-white text-black shadow-md"
                          : "bg-gray-50",
                        item.type === MenuItemType.Home
                          ? !navigation.isOpen
                            ? "cursor-pointer"
                            : ""
                          : "cursor-pointer",
                      )}
                      onClick={() => {
                        if (
                          item.type === MenuItemType.Home &&
                          navigation.isOpen
                        )
                          return;

                        if (
                          item.type === MenuItemType.Home &&
                          !navigation.isOpen
                        )
                          return toggleSidebar();

                        if (navigation.isOpen !== item.open) {
                          toggleSidebar();
                        }

                        navigate(`/a/${appId}${item.defaultPath}`);
                      }}
                    >
                      {item.type === MenuItemType.Home &&
                      !navigation.isOpen &&
                      !isSelected(
                        MENU_ITEMS.find(
                          (menuItem) =>
                            menuItem.type === MenuItemType.GettingStarted,
                        )!,
                      ) ? (
                        <ArrowLeftBackSquare
                          h={5}
                          w={5}
                          color="gray.900"
                          transform="rotate(180deg)"
                        />
                      ) : (
                        item.icon
                      )}
                      {item.type === MenuItemType.GettingStarted && (
                        <CircularProgress
                          thickness="14px"
                          value={progress}
                          color={isSelected(item) ? "primary" : "black"}
                          trackColor={
                            isSelected(item) ? "purple.100" : "gray.300"
                          }
                          size="18px"
                        />
                      )}
                    </div>
                  </motion.div>
                </TooltipTrigger>
                {item.label && (
                  <TooltipContent side="right">{item.label}</TooltipContent>
                )}
              </Tooltip>
            );
          },
        )}
      </div>
    </div>
  );
};

import { CircularProgress } from "@chakra-ui/react";
import { useState, useRef, useEffect } from "react";

import { Accordion } from "@/Components/ui/accordion";
import { ADD_ANALYTICS_TO_SIDEBAR } from "@/core/constants/features";
import { Event } from "@/core/design-system/components/Icon/Event";
import useFlag from "@/core/hooks/useFlag";
import { useGettingStarted } from "@/core/hooks/useGettingStarted";
import { useCurrentApp } from "core/hooks/useCurrentApp";
import { useNavigation } from "core/hooks/useNavigation";
import { useSidebarCollapse } from "core/hooks/useSidebarCollapse";
import { FavouriteObjectType } from "core/models/favourites";
import { cx } from "helpers/cx";
import {
  getCurrentMenuItem,
  MenuItemType,
} from "modules/Navigation/Sidebar/BaseSidebar";
import { Dashboards } from "modules/Navigation/Sidebar/Dashboards";
import { FavouritesSection } from "modules/Navigation/Sidebar/FavouritesSection";
import { Footer } from "modules/Navigation/Sidebar/Footer";
import { ExpandedHeader } from "modules/Navigation/Sidebar/Header/ExpandedHeader";
import { Integrations } from "modules/Navigation/Sidebar/Integrations";
import { Item } from "modules/Navigation/Sidebar/Item";
import { ItemContainer } from "modules/Navigation/Sidebar/ItemContainer";
import { ItemText } from "modules/Navigation/Sidebar/ItemText";
import { CurrentSection } from "modules/Navigation/Sidebar/NoAnalyticsSidebar";
import { People } from "modules/Navigation/Sidebar/People";

export interface IDynamicExpandedSidebarProps {
  currentSection: CurrentSection;
}

export enum Items {
  People = "people",
  Integrations = "integrations",
  Settings = "settings",
  Dashboard = "dashboard",
  Favourites = "favourites",
}

export const Sidebar: React.FC<IDynamicExpandedSidebarProps> = ({
  currentSection,
}) => {
  const { id: appId } = useCurrentApp();
  const { width } = useNavigation();
  const [showCollapseIcon, setShowCollapseIcon] = useState(false);
  const [openItems, setOpenItems] = useState<Items[]>([Items.People]);
  const currentBaseSidebarItem = getCurrentMenuItem();
  const { toggleViewsCollapse, toggleAudiencesCollapse } = useSidebarCollapse();
  const [isScrolled, setIsScrolled] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const hasAnalyticsToSidebar = useFlag(ADD_ANALYTICS_TO_SIDEBAR);
  const { progress, showGettingStarted } = useGettingStarted();

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const scrollTop = scrollContainerRef.current.scrollTop;
        setIsScrolled(scrollTop > 0);
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div
      onMouseEnter={() => setShowCollapseIcon(true)}
      onMouseLeave={() => setShowCollapseIcon(false)}
      className={cx(
        "flex h-full min-h-screen flex-col justify-between overflow-auto bg-gray-50",
        width && `w-[${width}]`,
      )}
    >
      <div
        ref={scrollContainerRef}
        className="scrollbar-hide flex h-[80vh] w-full flex-col gap-1 overflow-y-scroll"
      >
        <Accordion
          type="multiple"
          defaultValue={openItems}
          onValueChange={(value) => {
            if (value.includes(Items.People)) {
              toggleAudiencesCollapse();
            } else if (value.includes(Items.Dashboard)) {
              toggleViewsCollapse();
            }
            setOpenItems(value as Items[]);
          }}
        >
          <div
            className={cx(
              "top-0 z-10 w-full bg-gray-50 transition-all duration-200",
              isScrolled && "border-b border-gray-200",
            )}
          >
            <ExpandedHeader
              showCollapseIcon={showCollapseIcon}
              showNewReportButton={
                currentBaseSidebarItem?.type === MenuItemType.Dashboards
              }
              showSettings={false}
              showDevelopers={false}
              showBilling={false}
              showManageTeam={false}
            />
          </div>
          <div className="flex flex-col gap-y-[5px]">
            {showGettingStarted && (
              <Item
                isCurrent={currentSection === "Getting started"}
                to={`/a/${appId}/getting-started`}
                className="ml-2 flex items-center gap-2"
              >
                <CircularProgress
                  thickness="14px"
                  value={progress}
                  color={"black"}
                  trackColor={"gray.300"}
                  size="13px"
                  className="pl-[3px]"
                />
                <ItemText className="pl-[0px]">Getting started</ItemText>
              </Item>
            )}
            <People />

            <ItemContainer
              isCurrent={currentSection === "Events"}
              to={`/a/${appId}/events`}
              className="py-0"
            >
              <Event h={"18px"} w={"18px"} />

              <ItemText className="relative -left-[2px]">Events</ItemText>
            </ItemContainer>
            <Integrations />

            {hasAnalyticsToSidebar && <Dashboards />}

            <FavouritesSection
              objectTypes={
                currentBaseSidebarItem?.type === MenuItemType.People
                  ? [
                      FavouriteObjectType.Audience,
                      FavouriteObjectType.Group,
                      FavouriteObjectType.Company,
                      FavouriteObjectType.User,
                    ]
                  : [FavouriteObjectType.View, FavouriteObjectType.Report]
              }
            />
          </div>
        </Accordion>
      </div>
      <div
        className={cx(
          "absolute bottom-0 h-auto w-full bg-gray-50 transition-all duration-200",
          isScrolled && "border-t border-gray-200",
        )}
      >
        <Footer />
      </div>
    </div>
  );
};

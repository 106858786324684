import { createIcon } from "@chakra-ui/icons";
import React from "react";

export const Stripe = createIcon({
  viewBox: "0 0 11 14",
  path: (
    <svg
      width="11"
      height="14"
      viewBox="0 0 11 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.79055 2.86154C4.03937 2.86154 3.58583 3.05934 3.58583 3.57363C3.58583 5.05055 8.98583 4.35165 9 8.28132C9 10.6418 6.97323 12 4.0252 12C2.8063 12 1.47402 11.7758 0.155905 11.2484V8.10989C1.34646 8.71648 2.84882 9.16484 4.0252 9.16484C4.8189 9.16484 5.38583 8.96703 5.38583 8.36044C5.38583 6.79121 0 7.37143 0 3.73187C0 1.3978 1.91339 0 4.79055 0C5.96693 0 7.12913 0.171428 8.30551 0.606593V3.70549C7.22835 3.16483 5.86772 2.86154 4.79055 2.86154Z"
        fill="#495057"
      />
    </svg>
  ),
});
